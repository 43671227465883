import { IOrderModel } from "../Models/OrderModel";
import client from "../Request/Request";

export default class OrderUpdateService {

    public async UpdateOrder(orderModel: IOrderModel): Promise<any>{
        var result = null;
        
        try{
            result = await client.Put(`orderUpdate/migrationOrder/${orderModel.orderNumber}`, null);
        }
        catch(ex: any){
            console.error(`[OrderUpdateService] PostSendFtp - Error to request ${ex}`);

            if(ex.response.status === 401)
                throw new Error("No permission or login expired");
            else
                throw new Error("Error to update order");
        }

        return result;
    }
}