import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import OrderService from '../Services/OrderService';
import { formatDate } from 'date-fns';
import { IOrderModel } from '../Models/OrderModel';
import SendFtpModal from '../Modal/FTP/SendFtpModal';
import RequestUpdateOrderModal from '../Modal/Update/RequestUpdateOrderModal';
import { ProgressSpinner } from 'primereact/progressspinner';
import ExecuteScriptService from '../Services/ExecuteScriptService';

const TableComponentOrder = () => {
    const [isLoading, setLoading] = useState(false);
    const [isLoadingScript, setLoadingScript] = useState<boolean>(false);
    const [orders, setOrders] = useState<IOrderModel[]>([]);
    const [expandedRows, setExpandedRows] = useState(undefined);
    const toast = useRef<Toast>(null);

    const orderService = new OrderService();

    const handleGetAllOrders = async () => {
        try{

            setLoading(true);

            var response = await orderService.GetAllOrders();

            if(response.status === 200)
                setOrders(response.data);
            else
                setOrders([]);

        } catch(error: any){
            setOrders([]);
            showMessageError(error.message);
            console.error("Error to find all orders:", error);
        } finally{
            setLoading(false);
        }
    }

    useEffect(() => {

        return () => {
            handleGetAllOrders();
        }
    }, []);

    const showMessageError = (msg: string) => {
        if(toast && toast.current)
            toast.current.show({ severity: "error", summary: "Error", detail: msg, life: 4000 });
    }

    const showMessageInfo = (msg: string) => {
        if(toast && toast.current)
            toast.current.show({ severity: "info", summary: "Info", detail: msg, life: 4000 });
    }

    const formatDateColumn = (rowData: any, prop: any) => {
        if(undefined !== rowData[prop.field])
            return formatDate(new Date(rowData[prop.field]), 'EEE, MMM dd yyyy');
        else
            return '';
    }

    const formatDataBoolean = (rowData: any, prop: any) => {
        if(undefined !== rowData[prop.field])
            return JSON.parse(rowData[prop.field]) ? 'Yes' : 'No';
        else
            return '';
    }

    const formatShipments = (rowData: any, prop: any) => {
        if(undefined !== rowData[prop.field])
            return `${rowData[prop.field]} / ${rowData['numberOfUnits']}`;
        else
            return '';
    }

    const buttonSendFtp = (rowData: IOrderModel) => {
        return (rowData?.successSendFtp ?? false)
            ? null
            : <SendFtpModal order={rowData} callback={handleGetAllOrders} />;
    }

    const buttonUpdateOrder = (rowData: IOrderModel) => {
        return (rowData?.updateOrder ?? false)
            ? <RequestUpdateOrderModal order={rowData} callback={handleGetAllOrders} />
            : null;
    }

    const runApiScript = async () => {
        try{

            setLoadingScript(true);

            var response = await new ExecuteScriptService().Execute();

            console.log(`runApiScript [${response}]`);

            // if(response.status === 200)
            //     showMessageInfo("Completed, the list will be updated");

            showMessageInfo("Completed, the list will be updated");
            await handleGetAllOrders();

        } catch(error: any){
            showMessageError(error.message);
            console.error("Error to execute script:", error);
        } finally{
            setLoadingScript(false);
        }

        // fetch('http://localhost:5241/api/Script')
        //     .then(response => {
        //         alert(response);
        //         console.log(`Success response [${response}]`);
        //     })
        //     .catch(error => {
        //         console.log(`There was an error running the Python script: error [${error}]`);
        //     })
        //     .finally(() => {
        //         showMessageInfo("Completed, the list will be updated");
        //         setLoadingScript(false);
        //         handleGetAllOrders();
        //     });
    };

    const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
    const paginatorRight = <Button type="button" icon="pi pi-download" text />;

    const columns = [
        { header: 'FTP', field: '', body: buttonSendFtp },
        { header: 'Update', field: '', body: buttonUpdateOrder },
        { header: 'Order Number', field: 'orderNumber' },
        { header: 'Customer', field: 'customer' },
        { header: 'Status', field: 'status' },
        { header: 'Ship To', field: 'shipTo' },
        { header: 'Bill To', field: 'billTo' },
        { header: 'Created At', field: 'createdAt', body: formatDateColumn },
        { header: 'Updated At', field: 'updatedAt', body: formatDateColumn },
        { header: 'Ship From', field: 'shipFrom' },
        { header: 'Price List', field: 'priceList' },
        { header: 'Customer Reference', field: 'customerReference' },
        { header: 'Is Tax Inclusive', field: 'isTaxInclusive', body: formatDataBoolean },
        { header: 'Currency', field: 'currency' },
        { header: 'Shipments', field: 'shipmentsUnits', body: formatShipments }
    ];

    const columnsSubTable = [
        { header: 'Product', field: 'name' },
        { header: 'Unit', field: 'unit' },
        { header: 'SKU', field: 'sku' },
        { header: 'MAC', field: 'mac' },
        { header: 'Quantity', field: 'quantity' },
        { header: 'Invoiced', field: 'invoiced' },
        { header: 'Returned', field: 'returned' },
        { header: 'Shipped', field: 'shipped' },
        { header: 'Discount (%)', field: 'discount' },
        { header: 'Price', field: 'price' },
        { header: 'Tax', field: 'tax' },
        { header: 'Total', field: 'total' }
    ];    

    const handleExportToCSV = () => {
        if((orders ?? []).length > 0){ 
            let csvContent = "data:text/csv;charset=utf-8,";
        
            // Adding column headers
            const headers = (columns?.slice(1) ?? []).map(column => column.header).join(";");
            csvContent += `${headers}\r\n`;
        
            // Adding rows
            (orders ?? []).forEach((row: any) => {
                const rowData = (columns?.slice(1) ?? []).map((column: any) => `"${undefined != row[column.field] ? row[column.field] : ''}"`).join(";");
                csvContent += `${rowData}\r\n`;

                // Adding subtable
                const headersSubTable = columnsSubTable.map(column => column.header).join(';');
                csvContent += `;${headersSubTable}\r\n`;

                (row?.products ?? []).forEach((rowSub: any) => {
                    const rowSubData = columnsSubTable.map(column => `"${rowSub[column.field]}"`).join(";");
                    csvContent += `;${rowSubData}\r\n`;
                });
            });
        
            // Encoding URI and triggering download
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', 'export.csv'); // Filename as 'export.csv'
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
      };

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" icon="pi pi-file" rounded onClick={handleExportToCSV} data-pr-tooltip="CSV" >
                Export to CSV
            </Button>
            <Button type="button" rounded onClick={runApiScript} style={{ marginLeft: '20px'}} >
                {
                    isLoadingScript
                        ? <ProgressSpinner style={{width: '20px', height: '20px', marginRight: '10px'}} strokeWidth="5" />
                        : null
                }
                Run Script
            </Button>
        </div>
    );

    const rowExpansionTemplate = (data: any) => {
        return (
            <div className="p-3">
                <DataTable value={data.products} style={{ fontSize: 'small' }} >
                    {columnsSubTable.map((col, index) => (
                        <Column sortable key={index} field={col.field} header={col.header} />    
                    ))}
                </DataTable>
            </div>
        );
    };

    const allowExpansion = (rowData: IOrderModel) => {
        return (rowData?.products ?? []).length > 0;
    };

    return(
        <div>
            <Toast ref={toast} />
            <div className="card" style={{ marginLeft: '20px', marginRight: '20px' }}>
                <DataTable
                    rowExpansionTemplate={rowExpansionTemplate}
                    header={header}
                    emptyMessage="No registry available"
                    loading={isLoading}
                    value={orders}
                    expandedRows={expandedRows}
                    onRowToggle={(e: any) => setExpandedRows(e.data)}
                    stripedRows
                    sortMode="multiple"
                    removableSort
                    paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    paginatorLeft={paginatorLeft}
                    paginatorRight={paginatorRight}
                    tableStyle={{ minWidth: '50rem', fontSize: 'small' }}
                >
                    <Column expander={allowExpansion} style={{ width: '5rem' }} />
                    {columns.map((col, index) => (
                        <Column sortable key={index} field={col.field} header={col.header} body={col.body} />    
                    ))}
                </DataTable>
            </div>
        </div>
    );
}

export default TableComponentOrder;