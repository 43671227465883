import axios from "axios";

class Request{
    //private _urlBase = "http://localhost:5011";
    private _urlBase = "https://slm-integration-system-api.onrender.com";

    public _getToken(): string | null {
        return localStorage.getItem('token');
    }

    public async Get( url: string ): Promise<any>{
        let ret: any = null;

        await axios.get( `${this._urlBase}/${url}`, {
            headers: {
                Authorization: `Bearer ${this._getToken()}`
            }
        } )
        .then( result => {
            ret = result
        } );

        return ret;
    }

    public async Post( url: string, data: any ): Promise<any>{
        let ret: any = null;

        await axios.post( `${this._urlBase}/${url}`, data, {
            headers: {
                Authorization: `Bearer ${this._getToken()}`
            }
        } )
        .then( result => {
            ret = result;
        } );

        return ret;
    }

    public async Put( url: string, data: any ): Promise<any>{
        let ret: any = null;

        await axios.put( `${this._urlBase}/${url}`, data, {
            headers: {
                Authorization: `Bearer ${this._getToken()}`
            }
        } )
        .then( result => {
            ret = result;
        } );

        return ret;
    }
}

const client = new Request();
export default client;