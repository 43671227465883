import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Components/Login/Login';
import Dashboard from './Components/Dashboard/Dashboard.tsx';
import ProtectedRoute from './Components/Dashboard/ProtectedRoute';
import CSVReader from './Components/CsvReader/CSVReader.tsx';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={
          //<ProtectedRoute>
            <Dashboard />
          //</ProtectedRoute>
        } />
      </Routes>
    </Router>
  );
}

export default App;
