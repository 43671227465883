import client from "../Request/Request";

export default class AuthService {

    public async Login(email: string, password: string): Promise<any>{
        var result = null;
        
        try{

            result = await client.Post(
                "auth/login",
                {
                    email,
                    password
                }
            );

            if (result.data.success) {
                localStorage.setItem('isAuthenticated', 'true');
                localStorage.setItem('token', result.data.access_token);
            }
        }
        catch(ex: any){
            console.error(`Login - Error to request ${ex}`);
            throw new Error(ex.message);
        }

        return result;
    }
}