import { IFtpOrderModel } from "../Models/FtpOrderModel";
import client from "../Request/Request";

export default class OrderService {

    public async GetAllOrders(): Promise<any>{
        var result = null;
        
        try{
            result = await client.Get("order");
        }
        catch(ex: any){
            console.error(`[OrderService] GetAllOrders - Error to request ${ex}`);

            if(ex.response.status === 401)
                throw new Error("No permission or login expired");
            else
                throw new Error("Error to find all orders");
        }

        return result;
    }

    public async PostSendFtp(order: IFtpOrderModel): Promise<any>{
        var result = null;
        
        try{
            result = await client.Post("order/sendFtp", order);
        }
        catch(ex: any){
            console.error(`[OrderService] PostSendFtp - Error to request ${ex}`);

            if(ex.response.status === 401)
                throw new Error("No permission or login expired");
            else
                throw new Error("Error to send ftp");
        }

        return result;
    }
}