import React, { useRef, useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { IOrderModel } from "../../Models/OrderModel";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import OrderService from "../../Services/OrderService";
import { ProgressSpinner } from 'primereact/progressspinner';
import { IFtpOrderModel } from "../../Models/FtpOrderModel";

interface ISendFtpModalProps {
    order: IOrderModel,
    callback: () => void
}

const initialState: IFtpOrderModel = {
    warehouseInstructions: '',
    deliveryInstructions: '',
    deliveryReference: '',
    costOfFreight: '',
    assignCarrierUsingCode: '',
    deliveryPhoneNumber: '',
    deliveryEmailAddress: '',
    orderNumber: ''
};

export default function SendFtpModal(props: ISendFtpModalProps) {
    const [ftpOrderModel, setFtpOrderModel] = useState<IFtpOrderModel>(initialState);
    const [visible, setVisible] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const toast = useRef<Toast>(null);

    const orderService = new OrderService();

    const showMessageError = (msg: string) => {
        if(toast && toast.current)
            toast.current.show({ severity: "error", summary: "Error", detail: msg, life: 4000 });
    }

    const showMessageInfo = (msg: string) => {
        if(toast && toast.current)
            toast.current.show({ severity: "info", summary: "Info", detail: msg, life: 4000 });
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | { name?: string; value: any }>) => {
        const { name, value } = e.target;

        setFtpOrderModel(prev => ({
            ...prev,
            [name!]: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        await sendFtp();
    };

    const sendFtp = async () => {
        try{
            setLoading(true);

            ftpOrderModel.orderNumber = props?.order?.orderNumber ?? '';

            var result = await orderService.PostSendFtp(ftpOrderModel);

            if(result.status === 201)
                showMessageInfo("Sent successfully");
            else
                showMessageError("Error to sendFtp");

            props?.callback?.();

        }catch(error: any){
            showMessageError(error.message);
            console.error("Error to sendFtp:", error);
        }finally{
            setLoading(false);
            setVisible(false);
        }
    }

    const footerContent = (
        <div>
            <Button label="Cancel" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            <Button icon="pi pi-check" onClick={handleSubmit} autoFocus >Send</Button>
        </div>
    );

    var header: string = `(Send to FTP) Order ${props?.order?.orderNumber}`;

    return (
        <div className="card flex justify-content-center">
            <Toast ref={toast} />
            <Button icon="pi pi-external-link" onClick={() => setVisible(true)}>Send</Button>
            <Dialog header={header} visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent}>
                <form>
                {
                    isLoading
                    ? <div style={{ textAlign: 'center' }}>
                        <ProgressSpinner style={{width: '50px', height: '50px', marginTop: '10px'}} strokeWidth="5" />
                    </div>
                    : <div>
                        <div style={{ display: 'flex', gap: '1px' }}>
                            <div className="p-col-12 p-md-6" style={{ flex: 1, marginRight: '10px' }}>
                                <div className="p-grid p-fluid">
                                    <div className="p-col-6 p-md-6">
                                        <div className="p-field">
                                        <label htmlFor="warehouseInstructions">Warehouse Instructions</label>
                                        <InputText id="warehouseInstructions" name="warehouseInstructions" onChange={handleInputChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6" style={{ flex: 1, marginLeft: '10px' }}>
                                <div className="p-grid p-fluid">
                                    <div className="p-col-6 p-md-6">
                                        <div className="p-field">
                                            <label htmlFor="deliveryInstructions">Delivery Instructions</label>
                                            <InputText id="deliveryInstructions" name="deliveryInstructions" onChange={handleInputChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', gap: '1px', marginTop: '10px' }}>
                            <div className="p-col-12 p-md-6" style={{ flex: 1, marginRight: '10px' }}>
                                <div className="p-grid p-fluid">
                                    <div className="p-col-6 p-md-6">
                                        <div className="p-field">
                                        <label htmlFor="deliveryReference">Delivery Reference</label>
                                        <InputText id="deliveryReference" name="deliveryReference" onChange={handleInputChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6" style={{ flex: 1, marginLeft: '10px' }}>
                                <div className="p-grid p-fluid">
                                    <div className="p-col-6 p-md-6">
                                        <div className="p-field">
                                            <label htmlFor="costOfFreight">Cost of freight</label>
                                            <InputText id="costOfFreight" name="costOfFreight" onChange={handleInputChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6" style={{ marginTop: '10px' }}>
                            <div className="p-grid p-fluid">
                                <div className="p-col-6 p-md-6">
                                    <div className="p-field">
                                        <label htmlFor="assignCarrierUsingCode">Assign a Carrier using their code</label>
                                        <InputText id="assignCarrierUsingCode" name="assignCarrierUsingCode" onChange={handleInputChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', gap: '1px', marginTop: '10px' }}>
                            <div className="p-col-12 p-md-6" style={{ flex: 1, marginRight: '10px' }}>
                                <div className="p-grid p-fluid">
                                    <div className="p-col-6 p-md-6">
                                        <div className="p-field">
                                        <label htmlFor="deliveryPhoneNumber">Delivery phone number</label>
                                        <InputText type="number" id="deliveryPhoneNumber" name="deliveryPhoneNumber" onChange={handleInputChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6" style={{ flex: 1, marginLeft: '10px' }}>
                                <div className="p-grid p-fluid">
                                    <div className="p-col-6 p-md-6">
                                        <div className="p-field">
                                            <label htmlFor="deliveryEmailAddress">Delivery email address</label>
                                            <InputText type="email" id="deliveryEmailAddress" name="deliveryEmailAddress" onChange={handleInputChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </form>
            </Dialog>
        </div>
    )
}