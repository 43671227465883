import { useState } from "react";
import "./LoginStyle.css"; // Ensure this path is correct
import { useNavigate } from "react-router-dom";
// Import the icons
import email_icon from "../Assets/email.png"; // Adjust the path according to your project structure
import password_icon from "../Assets/password.png"; // Adjust the path according to your project structure
import AuthService from "../Services/AuthService";
import { Button } from "primereact/button";
import { ProgressSpinner } from 'primereact/progressspinner';

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginMessage, setLoginMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const authService = new AuthService();

  const handleLogin = async () => {
    try {

      setLoading(true);

      var response = await authService.Login(email, password);

      console.log(`handleLogin ${response.data.success}`);

      if (response.data.success)
        navigate('/dashboard'); // Redirect to the dashboard after successful login
      else
        setLoginMessage("Login failed: " + response.data.message);

    } catch (error) {
      console.error("Login error:", error);
      setLoginMessage("Login error: " + (error.response?.data?.message || error.message));
    } finally{
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="header">
        <div className="text">Southland Merchants</div>
        <div className="sub-text">Sign In</div>
        <div className="underline"></div>
      </div>
      <div className="inputs">
        <div className="input">
          <img src={email_icon} alt="Email" />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="input">
          <img src={password_icon} alt="Password" />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="forgot-password">
          Lost Password? <span>Click Here!</span>
        </div>
        <div className="submit-container">
          
          <div className="card flex justify-content-center">
            <Button
              type="button"
              rounded
              onClick={handleLogin} >
              {
                isLoading
                ? <ProgressSpinner style={{width: '30px', height: '30px', marginRight: '10px'}} strokeWidth="5" />
                : 'Log in'
              }
          </Button>
          </div>
        </div>
        {loginMessage && <div className="login-message">{loginMessage}</div>}
      </div>
    </div>
  );
}

export default Login;
