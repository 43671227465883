import axios from "axios";

export default class ExecuteScriptService {

    public async Execute(): Promise<any>{
        var ret = null;
        
        try{
            await axios.get("http://localhost:5241/api/Script")
            .then( result => {
                ret = result
            } );
        }
        catch(ex: any){
            console.error(`[ExecuteScriptService] Execute - Error to request ${ex}`);
            throw new Error("Error to execute script");
        }

        return ret;
    }
}