import { useNavigate } from 'react-router-dom';
import { Button } from "primereact/button";
import TableComponentOrder from "../Table/TableComponentOrder";

const Dashboard = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('token');
        navigate('/');
    };

    return(
        <div>
            <h1>Dashboard</h1>
            <p>Welcome to your dashboard. You are logged in.</p>
            <div style={{ marginBottom: '20px', marginLeft: '20px' }} >
                <Button type="button" rounded onClick={handleLogout} >
                    Logout
                </Button>
            </div>
            <TableComponentOrder />
        </div>
    );
}

export default Dashboard;