import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { useRef, useState } from "react";
import OrderUpdateService from "../../Services/OrderUpdateService";
import { ProgressSpinner } from "primereact/progressspinner";
import { IOrderModel } from "../../Models/OrderModel";

interface IRequestUpdateOrderModalProps {
    order: IOrderModel,
    callback: () => void
}

export default function RequestUpdateOrderModal(props: IRequestUpdateOrderModalProps) {
    const [visible, setVisible] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const toast = useRef<Toast>(null);

    const orderUpdateService = new OrderUpdateService();

    const showMessageError = (msg: string) => {
        if(toast && toast.current)
            toast.current.show({ severity: "error", summary: "Error", detail: msg, life: 4000 });
    }

    const showMessageInfo = (msg: string) => {
        if(toast && toast.current)
            toast.current.show({ severity: "info", summary: "Info", detail: msg, life: 4000 });
    }

    const handleSubmit = async (e: React.FormEvent) => {
        try{
            setLoading(true);

            await orderUpdateService.UpdateOrder(props.order);

            showMessageInfo("Update completed successfully");

            props?.callback?.();

        }catch(error: any){
            showMessageError(error.message);
        }finally{
            setLoading(false);
            setVisible(false);
        }
    };

    const footerContent = (
        <div>
            <Button label="Cancel" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            <Button icon="pi pi-check" onClick={handleSubmit} autoFocus >Yes</Button>
        </div>
    );

    return (
        <div className="card flex justify-content-center">
            <Toast ref={toast} />
            <Button type="button" icon="pi pi-download" text onClick={() => setVisible(true)}>Request update</Button>
            <Dialog header="Request update order" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent}>
                <p>
                    {
                        isLoading
                        ?   <div style={{ textAlign: 'center' }}>
                                <ProgressSpinner style={{width: '50px', height: '50px', marginTop: '10px'}} strokeWidth="5" />
                            </div>
                        :   `Would you like to update this order ${props?.order?.orderNumber ?? ''}?`
                    }
                </p>
            </Dialog>
        </div>
    );
}